import React, { useState, useCallback, memo } from "react";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import ARComponent from "../../components/arcomp/ARComponent";
import imageTargetSrc from "./LessonPlans.mind";
import ImageTargetSrc02 from "./LinkTojourney.mind";
import QrScanner from "react-qr-scanner";

// QR scanner wrapped in memo for efficiency
const WrappedQrScanner = memo(({ onScan, onError, style, ...props }) => (
  <QrScanner
    onScan={onScan}
    onError={onError}
    style={{
      ...style,
      width: "100%",
      maxWidth: "100vw",
      height: "auto",
      aspectRatio: "16 / 9",
    }}
    constraints={{ audio: false, video: { facingMode: "environment" } }}
    {...props}
  />
));

WrappedQrScanner.displayName = "WrappedQrScanner";

const LessonPlans = () => {
  const [hasScanned, setHasScanned] = useState({ ar: false, qr: false });
  const [buttonClicked, setButtonClicked] = useState(false);
  const navigate = useNavigate();

  // Triggered when any AR target is found
  const handleTargetFound = useCallback(
    (target) => {
      if (target === ImageTargetSrc02 && !hasScanned.ar) {
        if (window.confirm("要進入三民億旅遊戲嗎？")) {
          navigate("/"); // Navigate to homepage
        }
        setHasScanned((prev) => ({ ...prev, ar: true }));
        message.success("AR 目標找到並跳轉！", 2);
      } else if (target === imageTargetSrc && !hasScanned.ar) {
        setHasScanned((prev) => ({ ...prev, ar: true }));
        message.success("AR 目標找到了！", 2);
      }
    },
    [hasScanned, navigate]
  );

  const handleQRScan = useCallback(
    (data) => {
      if (data && typeof data === "string") {
        console.log("Scanned data:", data);
        if (data.includes("sanmingmemoryjourney") && !hasScanned.qr) {
          message.success("QR 碼掃描成功！", 2);
          setHasScanned((prev) => ({ ...prev, qr: true }));
        }
      }
    },
    [hasScanned]
  );

  const handleEndGame = useCallback(() => {
    if (window.confirm("你確定要離開遊戲嗎？")) {
      try {
        window.close();
      } catch (e) {
        console.error("無法自動關閉視窗", e);
        navigate("/");
      }
    }
  }, [navigate]);

  const scannerStyle = {
    width: "100%",
    aspectRatio: "16/9",
    marginBottom: "1rem",
  };

  return (
    <div className="container1">
      {/* AR Component */}
      <div style={scannerStyle}>
        <ARComponent
          imageTargetSrc={[imageTargetSrc, ImageTargetSrc02]}
          onTargetFound={handleTargetFound}
          isEnabled={!hasScanned.ar}
        />
      </div>

      {/* QR Scanner */}
      {!hasScanned.qr && (
        <div style={scannerStyle}>
          <WrappedQrScanner onScan={handleQRScan} delay={300} />
        </div>
      )}

      {/* Game Controls */}
      {hasScanned.ar && (
        <div className="buttonContainer">
          {!buttonClicked ? (
            <button
              className="foundButton"
              onClick={() => setButtonClicked(true)}
            >
              找到了
            </button>
          ) : (
            <div className="centered-content">
              <button className="endButton" onClick={handleEndGame}>
                結束遊戲
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default LessonPlans;
